<script setup lang="ts">
/**
 * @author Alexander Kenchoshvili
 */

//

import { SecondaryDataInterface } from "@/types/ContentType";
import { computed } from "vue";

const props = defineProps<{
  contentData: SecondaryDataInterface | undefined;
}>();

const textData = computed(() => props?.contentData?.data?.list[0]?.content?.[0]?.data);
</script>

<template>
  <div class="footer-text bg-black pos-rel">
    <div class="container">
      <article class="footer-text__editor p-16" v-html="textData" />
    </div>
  </div>
</template>

<style lang="scss">
.footer-text {
  background-color: #1f1f1f;
  z-index: 6;
  &__editor {
    @include editor($white, 10px);
    padding: 140px 84px;

    @include border;
    @include border(bottom);
    @include border(right);
    @include border(left);
    @include maxq(desktop-2k) {
      padding: 87px 55px;
    }
    @include maxq(desktop-lg) {
      padding: 64px 41px;
    }
    @include maxq(mobile) {
      padding: 32px 0px;
      border: none;
    }

    ul {
      list-style-type: none !important;
      opacity: 1;
      margin-top: 10px;

      li {
        margin-top: 5px;
        padding-left: 18px;
        position: relative;
        opacity: 1;

        &::before {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $mango;
          position: absolute;
          top: 6px;
          left: 0px;
        }
      }
    }

    p {
      opacity: 1;
    }
  }
}
</style>
